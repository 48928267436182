import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "container w-full text-center bg-white border-t-0 rounded-xl rounded-t-none" }
const _hoisted_2 = { class: "min-w-full divide-y divide-gray-200" }
const _hoisted_3 = { class: "px-6 py-4 whitespace-nowrap" }
const _hoisted_4 = { class: "flex flex-center space-x-4 items-center" }
const _hoisted_5 = { class: "md:text-2xl font-medium text-gray-900 pr-10" }
const _hoisted_6 = { class: "h-10 w-10" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "md:text-2xl font-medium text-gray-900" }
const _hoisted_9 = { class: "px-6 py-4 whitespace-nowrap text-sm md:text-2xl text-gray-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.scorelist, (person, index) => {
          return (_openBlock(), _createElementBlock("li", {
            class: "flex items-center justify-around bg-white",
            key: person._id
          }, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, _toDisplayString(index + 1), 1),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("img", {
                  class: "h-10 w-10 rounded-full",
                  src: person.image ? person.image : 'https://avatars.githubusercontent.com/u/21698755?s=88&v=4',
                  alt: ""
                }, null, 8, _hoisted_7)
              ]),
              _createElementVNode("div", _hoisted_8, _toDisplayString(person.name), 1)
            ]),
            _createElementVNode("div", _hoisted_9, _toDisplayString(person.points) + "b", 1)
          ]))
        }), 128))
      ])
    ])
  ]))
}