
import { Options, Vue } from "vue-class-component";
import { ScoreListType } from "../types/scoreboard/scoreboard-type";
import { Prop } from "vue-property-decorator";

@Options({
  components: {},
})
export default class ScoreCard extends Vue {
  @Prop() readonly scorelist!: Array<ScoreListType>;
}
