
import { Options, Vue } from "vue-class-component";

import Loading from "../components/Loading.vue";
import ScoreCard from "../components/ScoreCard.vue";
import { ScoreCoursesTabType, ScoreListType } from "../types/scoreboard/scoreboard-type";
import { scoreboardlistCoursesHandler, scoreboardListHandler, scoreboardlistSpecifiHandler } from "../handlers/scoreboard/scoreboard-handlers";

@Options({
  components: {
    ScoreCard,
    Loading,
  },
})
export default class Scoreboard extends Vue {
  private tabs: Array<ScoreCoursesTabType> = [];
  private selected_tab = -1;
  private scorelist_global: Array<ScoreListType> = [];
  private scorelist_specific: Record<number, Array<ScoreListType>> = [];

  mounted(): void {
    // Get tabs
    scoreboardlistCoursesHandler()
      .then((tabs: Array<ScoreCoursesTabType> | void) => {
        this.tabs = tabs || [];

        // Get specific
        this.tabs.forEach((tab: ScoreCoursesTabType) => {
          scoreboardlistSpecifiHandler(tab._id)
            .then((scorelist: Array<ScoreListType> | void) => {
              this.scorelist_specific[tab._id] = scorelist || [];
            })
            .catch((error) => {
              console.error(error);
            });
        });
      })
      .catch((error) => {
        console.error(error);
      });

    // Get general
    scoreboardListHandler()
      .then((scorelist: Array<ScoreListType> | void) => {
        this.scorelist_global = scorelist || [];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  select(id: number): void {
    this.selected_tab = id;
  }

  selectedCourse(): Array<ScoreListType> {
    if (this.selected_tab === -1) return this.scorelist_global;
    return this.scorelist_specific[this.selected_tab];
  }
}
