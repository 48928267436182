import { AlertSeverities, showAlert } from "@/helpers/alerts";
import { request } from "@/requests/api-request";
import { ScoreCoursesTabType, ScoreListType } from "@/types/scoreboard/scoreboard-type";
import { AxiosError } from "axios";

export async function scoreboardListHandler(): Promise<void | Array<ScoreListType>> {
  const data = await request(undefined, "GET", `scoreboard`, undefined, undefined, undefined)
    .then((response) => {
      if (response === undefined) {
        return showAlert("Uh-oh", "Naše chyba a něco se strašně pokazilo. Zkus to ještě jednou popřípadě počkat než vše napravíme.", AlertSeverities.fatal);
      } else {
        return response.payload;
      }
    })
    .catch((error: AxiosError) => {
      if (!error.code) {
        console.error(99, error);
      }
    });

  return data;
}

export async function scoreboardlistSpecifiHandler(user_id: number): Promise<void | Array<ScoreListType>> {
  const data = await request(undefined, "GET", `scoreboard/${user_id}`, undefined, undefined, undefined)
    .then((response) => {
      if (response === undefined) {
        return showAlert("Uh-oh", "Naše chyba a něco se strašně pokazilo. Zkus to ještě jednou popřípadě počkat než vše napravíme.", AlertSeverities.fatal);
      } else {
        return response.payload;
      }
    })
    .catch((error: AxiosError) => {
      if (!error.code) {
        console.error(99, error);
      }
    });

  return data;
}

export async function scoreboardlistCoursesHandler(): Promise<Array<ScoreCoursesTabType>> {
  const data = await request(undefined, "GET", `scoreboard/course`, undefined, undefined, undefined)
    .then((response) => {
      if (response === undefined) {
        return showAlert("Uh-oh", "Naše chyba a něco se strašně pokazilo. Zkus to ještě jednou popřípadě počkat než vše napravíme.", AlertSeverities.fatal);
      } else {
        return response.payload;
      }
    })
    .catch((error: AxiosError) => {
      if (!error.code) {
        console.error(99, error);
      }
    });
  return data;
}
