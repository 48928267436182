import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col my-5" }
const _hoisted_2 = { class: "-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8" }
const _hoisted_3 = { class: "py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8" }
const _hoisted_4 = { class: "shadow overflow-hidden border-b border-gray-200 sm:rounded-lg" }
const _hoisted_5 = { class: "list-reset flex border-b" }
const _hoisted_6 = { class: "-mb-px mr-1" }
const _hoisted_7 = ["onClick", "aria-current"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_score_card = _resolveComponent("score-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", null, [
            _createElementVNode("ul", _hoisted_5, [
              _createElementVNode("li", _hoisted_6, [
                _createElementVNode("button", {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.select(-1))),
                  class: _normalizeClass([
                    _ctx.selected_tab === -1 ? 'border-l border-t border-r rounded-t font-semibold hover:text-blue-darker' : 'hover:text-purple-500',
                    'bg-white inline-block py-2 px-4 focus:outline-none',
                  ])
                }, " Global ", 2),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.tabs, (item) => {
                  return (_openBlock(), _createElementBlock("button", {
                    onClick: ($event: any) => (_ctx.select(item._id)),
                    key: item._id,
                    class: _normalizeClass([
                    _ctx.selected_tab == item._id ? 'border-l border-t border-r rounded-t font-semibold hover:text-blue-darker' : 'hover:text-purple-500',
                    'bg-white inline-block py-2 px-4 focus:outline-none',
                  ]),
                    "aria-current": item.current ? 'page' : undefined
                  }, _toDisplayString(item.abbreviation), 11, _hoisted_7))
                }), 128))
              ])
            ])
          ]),
          _createVNode(_component_score_card, {
            scorelist: _ctx.selectedCourse()
          }, null, 8, ["scorelist"])
        ])
      ])
    ])
  ]))
}